var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-event-drawer" },
    [
      _c(
        "a-spin",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { spinning: _vm.loading },
        },
        [
          _vm.eventModel
            ? _c(
                "div",
                [
                  _c(
                    "jtl-card",
                    { attrs: { title: "告警信息" } },
                    [
                      _vm.eventModel.processStatus === "WCL"
                        ? _c(
                            "a",
                            {
                              staticClass: "jtl-link",
                              attrs: { slot: "extra" },
                              on: { click: _vm.handleClick },
                              slot: "extra",
                            },
                            [_vm._v("去处理")]
                          )
                        : _vm._e(),
                      !_vm.eventModel.workOrderStatus &&
                      _vm.workOrderOptions &&
                      _vm.workOrderOptions.length
                        ? _c(
                            "template",
                            { slot: "extra" },
                            [
                              _vm.workOrderOptions.length === 1
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRequestService({
                                            key: _vm.workOrderOptions[0].value,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("报修 "),
                                      _c("a-icon", { attrs: { type: "down" } }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "a-dropdown",
                                    { attrs: { trigger: ["click"] } },
                                    [
                                      _c(
                                        "a-menu",
                                        {
                                          staticClass: "jtl-link",
                                          attrs: { slot: "overlay" },
                                          on: { click: _vm.addRequestService },
                                          slot: "overlay",
                                        },
                                        _vm._l(
                                          _vm.workOrderOptions,
                                          function (option) {
                                            return _c(
                                              "a-menu-item",
                                              { key: option.value },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.name) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "a",
                                        { staticClass: "jtl-link" },
                                        [
                                          _vm._v("报修 "),
                                          _c("a-icon", {
                                            attrs: { type: "down" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "", column: 2 },
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警时间" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.eventModel.alarmTime
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "设备名称" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.deviceName) + " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警类型" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.alarmSourceName) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警源" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.identifierName) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警级别" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.alarmGradeDesc) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警描述" } },
                            [_vm._v(" " + _vm._s(_vm.eventModel.remark) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "持续时间" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("timeDuration")(
                                      _vm.eventModel.alarmDuration
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "设备位置" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.locationName) + " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "是否恢复" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.recover ? "是" : "否") +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "处理状态" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.processStatusDesc) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.eventModel.createReport
                            ? _c(
                                "a-descriptions-item",
                                { attrs: { label: "运行报告" } },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        ghost: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.detailClick(_vm.eventModel)
                                        },
                                      },
                                    },
                                    [_vm._v("报告")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm.eventModel.cameraList &&
                  _vm.eventModel.cameraList.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "jtl-card",
                            { attrs: { title: "实时监控" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.cameraList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small",
                                  },
                                },
                                [
                                  _c("a-table-column", {
                                    key: "name",
                                    attrs: {
                                      title: "名称",
                                      "data-index": "name",
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "status",
                                    attrs: {
                                      title: "状态",
                                      "data-index": "status",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "action",
                                    attrs: { title: "预览", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (text, record) {
                                            return [
                                              _c("a-icon", {
                                                staticClass: "camera-image",
                                                attrs: { type: "video-camera" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.previewCamera(
                                                      record
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      78812235
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.eventModel.imageList &&
                  _vm.eventModel.imageList.length > 0
                    ? _c(
                        "div",
                        [
                          _c("br"),
                          _c(
                            "jtl-card",
                            { attrs: { title: "抓拍图片" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.imageList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small",
                                  },
                                },
                                [
                                  _c("a-table-column", {
                                    key: "name",
                                    attrs: {
                                      title: "名称",
                                      "data-index": "name",
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "createdTime",
                                    attrs: {
                                      title: "时间",
                                      "data-index": "createdTime",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "action",
                                    attrs: { title: "", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (text, record) {
                                            return [
                                              record.url
                                                ? _c("a-icon", {
                                                    staticClass: "camera-image",
                                                    attrs: { type: "picture" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.previewImage(
                                                          record
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      622461355
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.eventModel.processStatus === "YCL"
                    ? _c(
                        "div",
                        [
                          _c("br"),
                          _c(
                            "jtl-card",
                            { attrs: { title: "处理记录" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.handleList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small",
                                  },
                                },
                                [
                                  _c("a-table-column", {
                                    key: "createdTime",
                                    attrs: { title: "处理时间", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (text, record) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      record.createdTime
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1047879187
                                    ),
                                  }),
                                  _c("a-table-column", {
                                    key: "processMan",
                                    attrs: {
                                      title: "处理人",
                                      "data-index": "processMan",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "comments",
                                    attrs: {
                                      title: "处理意见",
                                      "data-index": "comments",
                                      width: 150,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("br"),
                      _vm.repairRecordList && _vm.repairRecordList.length > 0
                        ? _c(
                            "jtl-card",
                            { attrs: { title: "报修记录" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.repairRecordList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small",
                                  },
                                },
                                [
                                  _c("a-table-column", {
                                    key: "createdDT",
                                    attrs: { title: "报修时间", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (text, record) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      record.createdDT
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      417951158
                                    ),
                                  }),
                                  _c("a-table-column", {
                                    key: "createdBy",
                                    attrs: {
                                      title: "报修人",
                                      "data-index": "createdBy",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "code",
                                    attrs: {
                                      title: "工单编号",
                                      "data-index": "code",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "flowStatusName",
                                    attrs: {
                                      title: "工单状态",
                                      "data-index": "flowStatusName",
                                      width: 150,
                                    },
                                  }),
                                  _c("a-table-column", {
                                    key: "action",
                                    attrs: { title: "操作", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (text, record) {
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showDetail(
                                                        _vm.ViewModeType.VIEW,
                                                        record
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看工单")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      114796493
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("jtl-entity-dialog", {
        ref: "handleFormDialog",
        on: {
          dialogOK: function ($event) {
            return _vm.handleFormDialog(_vm.eventId)
          },
        },
      }),
      _c(
        "a-modal",
        {
          staticClass: "alarm-event-drawer-image-modal",
          attrs: {
            title: _vm.selectedImgTitle,
            visible: _vm.imageVisible,
            width: "70vw",
            footer: null,
          },
          on: {
            ok: function ($event) {
              _vm.imageVisible = false
            },
            cancel: function ($event) {
              _vm.imageVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", "object-fit": "contain" },
            attrs: { src: _vm.selectedImg },
          }),
        ]
      ),
      _c(
        "a-modal",
        {
          staticClass: "alarm-event-drawer-camera-modal",
          attrs: {
            title: _vm.selectedCameraTitle,
            visible: _vm.cameraModalVisible,
            footer: null,
            width: "800px",
          },
          on: {
            ok: function ($event) {
              _vm.cameraModalVisible = false
            },
            cancel: function ($event) {
              _vm.cameraModalVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "jtl-video-wrapper" },
            [_c("jtl-video-component", { ref: "jtlVideo" })],
            1
          ),
        ]
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            placement: _vm.placement,
            width: _vm.width,
            closable: _vm.closable,
            visible: _vm.drawerVisible,
            "destroy-on-close": true,
          },
          on: { close: _vm.drawerClose },
        },
        [
          _c(
            "div",
            {
              style: {
                backgroundColor: "#05BC9D",
                width: "100px",
                padding: "10px 0 10px 15px",
                borderRadius: "0px 50px 50px 0px",
                color: "white",
              },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" 工单详情  ")]
          ),
          _c("work-order-detail-component", {
            attrs: {
              "device-id": _vm.eventModel.deviceId,
              "location-id": _vm.eventModel.locationId,
              "view-type": _vm.viewType,
              "alarm-id": _vm.eventModel.id,
              "wo-config-id": _vm.selectedWoConfigId,
              "wo-id": _vm.selectedWorkOrderId,
            },
            on: {
              showDrawer: _vm.showDrawer,
              updateAlarmDetail: function ($event) {
                return _vm.handleFormDialog(_vm.eventModel.id)
              },
            },
          }),
        ],
        1
      ),
      _c("alarm-report-drawer", { ref: "deviceTypeDetailDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }