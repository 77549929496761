





























import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import AlarmEventService from '@common-src/service/alarm-event';

export default class AlarmReportTypeDrawer extends DrawerComponent {
    sourceModal = [];
    tableColumns: Array<any> = [
        {
            title: '设备属性名称',
            dataIndex: 'attrName'
        },
        {
            title: '值',
            dataIndex: 'value',
            scopedSlots: { customRender: 'value' }
        },
        {
            title: '参考范围',
            dataIndex: 'refs',
            customRender: (text, record) => {
                if (text && text?.type === 'RANGE' && Array.isArray(text?.range)) {
                    return `${text.range.join('~')}${record.unit ? record.unit : ''}`;
                }
                return text;
            }
        }
    ];

    drawerShow(model) {
        this.drawerOpen(model.id, model.name);
        this.init(model.id);
    }

    init(id: string) {
        this.sourceModal = [];
        this.startFullScreenLoading();
        AlarmEventService.getAlarmReportList(id).then((res) => {
            this.sourceModal = res;
            this.$forceUpdate();
        }).catch(error => {
            this.sourceModal = [];
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
