





























































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import AlarmEventService from '@common-src/service/alarm-event';
import { AlarmEventEntityModel } from '@common-src/entity-model/alarm-event-entity';
import { ViewModeType, WorkOrderType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import CameraPlayService from '@/pages/sub-system/service/camera-play-service';
import CommonService from '@common-src/service/common';
import WorkOrderDetailComponent from '@common-src/pages/dashboard/work-order/wo-detail.vue';
import DrawerComponent from '@common-src/mixins/drawer-component';
import WorkOrderService from '@common-src/service/work-order';
import AlarmReportDrawer from './alarm-report-drawer.vue';

@Component({
    components: {
        'work-order-detail-component': WorkOrderDetailComponent,
        'alarm-report-drawer': AlarmReportDrawer
    }
})
export default class AlarmEventDrawer extends Mixins(BaseComponent, DrawerComponent) {
    @Prop()
    eventId: string;

    loading: boolean = false;
    eventModel: any = {};
    imageVisible: boolean = false;
    selectedImg: string = '';
    selectedImgTitle: string = '';
    cameraModalVisible: boolean = false;
    selectedCameraTitle: string = '';

    workOrderOptions: any = [];
    selectedWoConfigId: string = '';
    selectedWorkOrderId: string = '';
    viewType: any = 0;
    ViewModeType = ViewModeType;
    repairRecordList: any = [];

    @Watch('eventId', { immediate: true, deep: true })
    eventIdChange(newVal: string): void {
        if (!newVal) {
            return;
        }
        this.initAlarmEventDetail(newVal);
    }

    showDetail(viewtype, record) {
        this.viewType = viewtype;
        this.selectedWorkOrderId = record.id;
        this.drawerVisible = true;
    }

    addRequestService(value) {
        this.viewType = ViewModeType.NEW;

        this.selectedWoConfigId = value.key;

        this.drawerVisible = true;
    }

    initWorkOrderList(id) {
        const params = {
            'page': 1,
            'limit': 1,
            'searchType': 'All',
            'formPropertyQueries': [
                {
                    'key': 'alarm_id',
                    'operate': 'EQ',
                    'value': id
                }
            ]
        };
        WorkOrderService.searchWorkOrderList(params).then(res => {
            this.repairRecordList = res.items || [];
        });
    }

    initWorkOrderOptions() {
        const params = {
            workOrderType: WorkOrderType.REPAIR,
            bizTypes: [this.eventModel.bizSystemType]
        };
        CommonService.getWorkOrderOptions(params).then(res => {
            this.workOrderOptions = res;
        });
    }

    showDrawer(info) {
        this.drawerVisible = info.visible;
    }

    detailClick(model) {
        (this.$refs.deviceTypeDetailDrawer as any).drawerShow({ id: model.id, name: model.deviceName });
    }
    handleClick() {
        const handleModel = new AlarmEventEntityModel([this.eventModel?.id]);
        handleModel.recover = this.eventModel.recover ? 1 : 0;
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogOpen(handleModel, AlarmEventService, ViewModeType.UPDATE);
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogTitle = '事件处理';
    }

    previewImage(modelItem) {
        this.selectedImg = modelItem.url;
        this.selectedImgTitle = modelItem.name;
        this.imageVisible = true;
    }

    previewCamera(modelItem) {
        this.selectedCameraTitle = `监控预览-${modelItem.name}`;
        this.cameraModalVisible = true;
        this.$nextTick(() => {
            const facilityId = modelItem?.id;
            CameraPlayService.getFacilityAttrs(facilityId).then(async(res) => {
                (this.$refs.jtlVideo as any).startPreviewByFacilityId(modelItem.id, _.get(res, '[0].attrMap'));
            });
        });
    }

    handleFormDialog(id: string) {
        this.$emit('handled');
        this.initAlarmEventDetail(id);
    }
    initAlarmEventDetail(id: string) {
        this.loading = true;
        AlarmEventService.retrieve(id || this.eventModel?.id).then(res => {
            this.eventModel = res;
            if (this.eventModel.processTime) {
                this.eventModel.handleList = [
                    {
                        createdTime: this.eventModel.processTime,
                        processMan: this.eventModel.processUser,
                        comments: this.eventModel.processComments
                    }
                ];
            }
            if (res?.workOrderStatus) {
                this.initWorkOrderList(id || this.eventModel?.id);
            } else {
                this.initWorkOrderOptions();
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    // keydown(ev) {
    //     switch (ev.key) {
    //         case 'ArrowLeft':
    //             this.$emit('change', -1);
    //             break;
    //         case 'ArrowRight':
    //             this.$emit('change', 1);
    //             break;
    //     }
    // }
}
