import { SubSystemQueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import moment, { Moment } from 'moment';
// import { eventLevelList } from '@common-src/filter/event-level';
import CommonService from '@common-src/service/common';
import { EnableType, PeriodTypeEnum, PropertyEntityType } from '@common-src/model/enum';
import { BaseModel } from '@common-src/model/base-model';
import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { enableList } from '@common-src/filter/enable';
import { dateFormat, timeDuration } from '@common-src/filter';
import { DEFAULT_FORMAT } from '@common-src/filter/date-format';
import { getEndTimeByIntervalType } from '@common-src/utils/time_uitil';

export const AlarmEventTableColumns = [
    {
        title: '告警时间',
        dataIndex: 'alarmTime',
        customRender: (text) => {
            return dateFormat(text);
        }
    },
    // {
    //     title: '告警名称',
    //     dataIndex: 'eventName',
    //     scopedSlots: { customRender: 'name' }
    // },
    {
        title: '设备名称',
        dataIndex: 'deviceName'
    },
    // {
    //     title: '告警类型',
    //     dataIndex: 'levelDesc'
    // },
    {
        title: '告警描述',
        dataIndex: 'remark'
    },
    {
        title: '持续时间',
        dataIndex: 'alarmDuration',
        customRender: (text) => {
            return timeDuration(text);
        }
    },
    {
        title: '告警级别',
        dataIndex: 'alarmGradeDesc'
    },
    // {
    //     title: '告警位置',
    //     dataIndex: 'locationName'
    // },
    {
        title: '处理状态',
        dataIndex: 'processStatusDesc'
    },
    {
        title: '报修状态',
        dataIndex: 'workOrderStatus',
        customRender: (text) => {
            return (text ? '已报修' : '未报修');
        }
    },
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];

export class AlarmEventEntityModel extends BaseModel {
    constructor(ids: Array<string>) {
        super();
        this.ids = ids;
    }
    ids: Array<string> = undefined;

    @FormControl({
        label: '处理意见',
        type: FormControlType.TEXT_AREA,
        required: false
    } as FormControlTextModel)
    comments: string = undefined;

    @FormControl({
        label: '是否恢复',
        type: FormControlType.RADIO_GROUP,
        options: enableList,
        readonly: true,
        required: false
    } as FormControlOptionModel)
    recover: EnableType = EnableType.DISABLE;

    toService(): any {
        return {
            ids: this.ids,
            comments: this.comments,
            recover: !!this.recover
        };
    }
}

export class AlarmEventQueryModel extends SubSystemQueryModel {
    @QueryControl({
        label: '告警级别',
        type: QueryControlType.SELECT,
        optionsPromise: async(param: any) => {
            const res = await CommonService.getPropertyOptions(param);
            return _.map(res, r => {
                return {
                    value: r.code,
                    name: r.name
                };
            });
        },
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        hasAllOption: true,
        index: 11
    })
    grade: string = JTL.CONSTANT.ALL_OPTION.value;

    // @QueryControl({
    //     label: '告警类型',
    //     type: QueryControlType.SELECT,
    //     options: eventLevelList,
    //     hasAllOption: true,
    //     index: 12
    // })
    // level: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '处理状态',
        type: QueryControlType.SELECT,
        options: [
            { value: 'YCL', name: '已处理' },
            { value: 'WCL', name: '未处理' }
        ],
        hasAllOption: true,
        index: 13
    })
    status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '报修状态',
        type: QueryControlType.SELECT,
        options: [
            { value: true, name: '已报修' },
            { value: false, name: '未报修' }
        ],
        hasAllOption: false,
        index: 14
    })
    workOrderStatus: boolean = undefined;

    @QueryControl({
        label: '告警时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        index: 15
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    @QueryControl({
        label: '设备名称',
        placeholder: '请输入设备名称',
        type: QueryControlType.TEXT,
        index: 16
    })
    deviceName: string = undefined;

    /**
     * true: 正在报警
     * false: 历史报警
     */
    active: boolean = undefined;

    reset() {
        this.grade = JTL.CONSTANT.ALL_OPTION.value;
        this.status = JTL.CONSTANT.ALL_OPTION.value;
        this.dateRange = JTL.CONSTANT.TODAY();
        this.deviceName = undefined;
        this.workOrderStatus = undefined;
    }

    toService() {
        if (this.dateRange && this.dateRange.length === 2) {
            return {
                params: {
                    endTime: moment(getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.DAY)).format(DEFAULT_FORMAT),
                    alarmGradeCode: this.grade,
                    // levels: this.level ? [this.level] : undefined,
                    startTime: this.dateRange[0]?.startOf('day').format(DEFAULT_FORMAT),
                    processStatus: this.status,
                    active: this.active,
                    deviceName: this.deviceName,
                    workOrderStatus: this.workOrderStatus,
                    model: false
                }
            };
        }
        return {
            params: {
                alarmGradeCode: this.grade,
                processStatus: this.status,
                active: this.active,
                deviceName: this.deviceName,
                workOrderStatus: this.workOrderStatus,
                model: false
            }
        };
    }
}
