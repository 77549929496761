var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: "right",
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _c(
            "div",
            { staticClass: "page-body page-body-margin page-body-contain" },
            [
              _c(
                "a-card",
                { attrs: { title: "报告列表" } },
                [
                  _c("a-table", {
                    attrs: {
                      columns: _vm.tableColumns,
                      "data-source": _vm.sourceModal,
                      pagination: false,
                      size: "small",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "value",
                        fn: function (text, record) {
                          return [
                            _c(
                              "span",
                              { class: { "jtl-del-link": record.status } },
                              [_vm._v(_vm._s(text) + _vm._s(record.unit))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }